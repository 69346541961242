<template>
  <q-page class="column">
    <div class="col-grow row" style="height:0">
      <div class="column col-auto full-height">
        <div class="col" style="height:0">
          <div class="q-pa-sm" style="height:100%;min-width:250px;max-width:350px;overflow:auto">
            <q-tree
              ref="tree"
              node-key="key"
              :nodes="nodes"
              :selected="selected"
              @update:selected="updateSelected"
            >
              <template v-slot:default-header="prop">
                <div class="row items-center no-wrap">
                  <q-icon v-if="prop.node.type === 1" name="source" color="orange" class="q-mr-sm"/>
                  <q-icon v-if="prop.node.type === 2" name="article" color="primary" class="q-mr-sm"/>
                  <div class="text-primary">{{ prop.node.classifyName || prop.node.formName }}</div>
                </div>
              </template>
            </q-tree>
          </div>
        </div>
      </div>
      <q-separator vertical/>
      <div v-if="currentFormId" class="col q-pa-md full-height column" style="width:0">
        <div class="col-auto q-mb-md">
          <q-form class="search-form row q-gutter-md">
            <q-select
              label="查询字段"
              v-model="param.fieldId"
              :options="options"
              @filter="filterFn"
              :use-input="!param.fieldId"
              option-label="fieldName"
              option-value="id"
              map-options
              emit-value
              dense
              clearable
              outlined
            />
            <q-input
              v-model="param.fieldContent"
              label="查询内容"
              placeholder="请输入查询内容"
              dense
              clearable
              outlined
            />
            <q-btn label="搜索" icon="search" type="submit" color="primary" unelevated @click="loadData"/>
            <q-btn label="添加材料" icon="add" color="primary" outline @click="toAddContract"/>
            <q-btn label="生成选中的材料" icon="play_for_work" color="primary" outline @click="generateContract"/>
          </q-form>
        </div>
        <div class="col" style="width:100%;height:0;overflow:hidden;">
          <div class="full-width full-height" style="overflow:hidden;">
            <q-markup-table flat bordered class="field-table fixed-last-column fixed-first-row" style="max-height:100%">
              <thead>
              <tr>
                <th class="text-left">
                  <q-checkbox :model-value="checkall" @update:model-value="updateCheckAll"/>
                </th>
                <th class="text-left">ID</th>
                <th v-for="(field, index) in fields" :key="index" class="text-left">{{ field.fieldName }}</th>
                <th class="text-left">操作</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,index) in data" :key="index">
                <td class="text-left">
                  <q-checkbox v-model="selection" :val="item.__id__"/>
                </td>
                <td class="text-left">{{ item.__id__ }}</td>
                <td v-for="(field, index) in fields" :key="index" class="text-left">{{ item[field.fieldCode] }}</td>
                <td class="text-left">
                  <q-btn label="修改" class="q-mr-sm" color="primary" size="sm" padding="4px 8px" outline @click="toUpdateContract(item)"/>
                  <q-btn label="复制" class="q-mr-sm" color="primary" size="sm" padding="4px 8px" outline @click="toCopyContract(item)"/>
                  <q-btn label="删除" color="negative" size="sm" padding="4px 8px" outline @click="deleteContract(item)"/>
                </td>
              </tr>
              </tbody>
            </q-markup-table>
          </div>
        </div>
      </div>
    </div>
  </q-page>
  <teleport to="body">
    <q-dialog v-model="showFormDialog" persistent>
      <q-card style="width:470px;max-width:90vw;">
        <q-card-section class="row items-center">
          <div class="text-h6">材料信息</div>
          <q-space/>
          <q-btn icon="close" flat round dense v-close-popup/>
        </q-card-section>
        <q-separator/>
        <q-card-section class="scroll" style="max-height:60vh">
          <data-form :data="form" :form-id="currentFormId" ref="formEle"/>
        </q-card-section>
        <q-separator/>
        <q-card-section class="text-right">
          <q-btn color="primary" label="保存" unelevated @click="saveContract"/>
        </q-card-section>
      </q-card>
    </q-dialog>
  </teleport>
</template>

<script>
import { getCurrentInstance, nextTick, ref, watch } from 'vue'
import DataForm from './DataForm'

export default {
  components: { DataForm },
  setup () {
    const { proxy } = getCurrentInstance()
    const nodes = ref([])
    const selected = ref(null)
    const fields = ref([])
    const options = ref([])
    const data = ref([])
    const currentFormId = ref(null)
    const param = ref({
      fieldId: null,
      fieldContent: null,
      fieldOp: 1
    })
    const showFormDialog = ref(false)
    const form = ref(null)
    const checkall = ref(false)
    const selection = ref([])

    watch(currentFormId, () => {
      loadFields()
      loadData()
    })

    watch(selection, (value) => {
      checkall.value = value.length !== 0 && value.length === data.value.length
    })

    function updateSelected (value) {
      if (!value) {
        value = selected.value
      }
      const tree = proxy.$refs.tree
      const node = tree.getNodeByKey(value)
      if (node && node.type === 1) {
        tree.setExpanded(value, !tree.isExpanded(value))
      }
      if (node && node.type === 2) {
        currentFormId.value = node.id
      }
      selected.value = value
    }

    function updateCheckAll (value) {
      checkall.value = value
      if (value) {
        selection.value = data.value.map((item) => item.__id__)
      } else {
        selection.value = []
      }
    }

    function toAddContract () {
      form.value = null
      showFormDialog.value = true
    }

    function toUpdateContract (item) {
      form.value = item
      showFormDialog.value = true
    }

    function toCopyContract (item) {
      item = Object.assign({}, item)
      delete item.__id__
      form.value = item
      showFormDialog.value = true
    }

    async function saveContract () {
      const formData = await proxy.$refs.formEle.validateAndGetForm()
      await proxy.$server.post('/contractFormData/saveContractFormData', {
        formId: currentFormId.value,
        id: formData.__id__,
        formData
      })
      proxy.$message.tipsSuccess('保存成功')
      showFormDialog.value = false
      loadData()
    }

    async function deleteContract (item) {
      await proxy.$message.confirm('确定要删除该材料数据吗？')
      await proxy.$server.post('/contractFormData/deleteContractFormData', {
        formId: currentFormId.value,
        id: item.__id__
      })
      proxy.$message.tipsSuccess('删除成功')
      loadData()
    }

    async function generateContract () {
      const ids = selection.value
      if (ids.length === 0) {
        proxy.$message.tipsWarning('请选择要生成的材料')
        return
      }
      const url = await proxy.$server.post(
        '/contractFormData/generateContract',
        { formId: currentFormId.value, ids },
        { timeout: 30 * 60 * 1000 })
      window.open(url)
    }

    async function mapClassify (map, classifyList) {
      if (classifyList) {
        for (const classify of classifyList) {
          classify.key = `1-${classify.id}`
          classify.type = 1
          map[classify.id] = classify
          mapClassify(map, classify.children)
        }
      }
    }

    async function mergeContract (map, classifyList, contractList) {
      const roots = []
      for (const contract of contractList) {
        contract.key = `2-${contract.id}`
        contract.type = 2
        const classify = map[contract.classifyId]
        if (classify) {
          if (classify.children) {
            classify.children.push(contract)
          } else {
            classify.children = [contract]
          }
        } else {
          roots.push(contract)
        }
      }
      classifyList.push(...roots)
    }

    async function loadNodes () {
      const classifyList = await proxy.$server.get('/classify/tree')
      const contractList = await proxy.$server.post('/contractForm/contractFormList')
      const map = {}
      mapClassify(map, classifyList)
      mergeContract(map, classifyList, contractList)
      nodes.value = classifyList
    }

    async function loadFields () {
      if (currentFormId.value) {
        fields.value = await proxy.$server.post(
          '/contractFormField/contractFormFieldList', { formId: currentFormId.value })
      }
    }

    function filterFn (val, update) {
      if (val === '') {
        update(() => {
          options.value = fields.value
        })
        return
      }
      update(() => {
        const needle = val.toLowerCase()
        options.value = fields.value.filter(v => v.fieldName.toLowerCase().indexOf(needle) > -1)
      })
    }

    async function loadData () {
      checkall.value = false
      selection.value = []
      if (currentFormId.value) {
        const searchParam = Object.assign({ formId: currentFormId.value }, param.value)
        data.value = await proxy.$server.post('/contractFormData/contractFormDataList', searchParam)
      }
    }

    function selectFirstContract (parents, nodes) {
      if (nodes) {
        for (const node of nodes) {
          if (node.type === 2) {
            const tree = proxy.$refs.tree
            for (const p of parents) {
              tree.setExpanded(p.key, true)
            }
            updateSelected(node.key)
            return true
          } else {
            if (selectFirstContract(parents.concat(node), node.children)) {
              return true
            }
          }
        }
      }
    }

    async function init () {
      await loadNodes()
      nextTick(() => selectFirstContract([], nodes.value))
    }

    init()

    return {
      nodes,
      selected,
      fields,
      options,
      data,
      param,
      currentFormId,
      showFormDialog,
      form,
      checkall,
      selection,
      filterFn,
      updateSelected,
      updateCheckAll,
      toAddContract,
      toUpdateContract,
      toCopyContract,
      saveContract,
      deleteContract,
      generateContract,
      loadData
    }
  }
}
</script>
