<template>
  <q-form ref="formEle" class="q-gutter-md">
    <template v-for="(field,index) in fields" :key="index">
      <!-- 日期、时间、日期时间 -->
      <x-datetime
        v-if="field.fieldType === 4 || field.fieldType === 5 || field.fieldType === 6"
        v-model="form[field.fieldCode]"
        :type="getDateTimeType(field)"
        :rules="getRules(field)"
        :label="field.fieldName"
        :label-slot="field.fieldRequired"
        dense
        clearable
        outlined
        hide-bottom-space
      >
        <template v-if="field.fieldRequired" v-slot:label>
          <span class="text-negative">*</span>
          <span>{{ field.fieldName }}</span>
        </template>
      </x-datetime>
      <!-- 整数、字符串、数值 -->
      <q-input
        v-else
        v-model="form[field.fieldCode]"
        :rules="getRules(field)"
        :label="field.fieldName"
        :label-slot="field.fieldRequired"
        :maxlength="getLength(field)"
        dense
        clearable
        outlined
        hide-bottom-space
      >
        <template v-if="field.fieldRequired" v-slot:label>
          <span class="text-negative">*</span>
          <span>{{ field.fieldName }}</span>
        </template>
      </q-input>
    </template>
  </q-form>
</template>

<script>
import { getCurrentInstance, nextTick, ref } from 'vue'
import { XDatetime } from '../../../components/datetime'
import { qDigit, qInteger, qNotBlank } from '../../../utils/validate-utils'

export default {
  components: { XDatetime },
  props: {
    formId: { required: true },
    data: { default: null }
  },
  setup (props) {
    const { proxy } = getCurrentInstance()
    const fields = ref([])
    const form = ref(Object.assign({}, props.data))

    async function loadFields () {
      fields.value = await proxy.$server.post(
        '/contractFormField/contractFormFieldList', { formId: props.formId })
    }

    function getDateTimeType (field) {
      if (field.fieldType === 4) {
        return 'date'
      }
      if (field.fieldType === 5) {
        return 'time'
      }
      return 'datetime'
    }

    function getRules (field) {
      const rules = []
      if (field.fieldRequired) {
        rules.push(qNotBlank)
      }
      if (field.fieldType === 1) {
        rules.push(qInteger)
      }
      if (field.fieldType === 3) {
        rules.push(qDigit(field.fieldLength, field.fieldAccuracy))
      }
      return rules
    }

    function getLength (field) {
      if (field.fieldType === 1) {
        return 19
      }
      if (field.fieldType === 2) {
        return field.fieldLength
      }
      if (field.fieldType === 3) {
        return field.fieldLength + 1
      }
    }

    async function validate () {
      return await proxy.$refs.formEle.validate()
    }

    async function resetValidation () {
      nextTick(() => proxy.$refs.formEle.resetValidation())
    }

    function getForm () {
      return Object.assign({}, form.value)
    }

    async function validateAndGetForm () {
      if (await validate()) {
        return getForm()
      }
      throw new Error('Form valid error')
    }

    loadFields()

    return {
      fields,
      form,
      getDateTimeType,
      getRules,
      getLength,
      validate,
      resetValidation,
      getForm,
      validateAndGetForm
    }
  }
}
</script>
